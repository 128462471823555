@import "tailwindcss/base";

@import "tailwindcss/components";
@import "components.css";

@import "tailwindcss/utilities";
@import "utilities.css";

body {
  position: relative;
  overflow: hidden;
  font-family: 'Object Sans', sans-serif !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  overflow-x: hidden;
  height: -webkit-fill-available;
}

.jSignature {
  border-radius: 0.25rem;
}

.ember-jsignature {
  width: 100%;
}

.file-upload {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}

